import { __decorate } from "tslib";
import { Vue, Component } from 'vue-property-decorator';
import { analyticsStore, alunoStore } from '@/utils/store-accessor';
import PeriodSelectorComponent from '@/components/PeriodSelectorComponent.vue';
import GenericListTotalCardComponent from '@/components/GenericListTotalCardComponent.vue';
import GenericListItemsCardComponent from '@/components/GenericListItemsCardComponent.vue';
import TextExpandableComponent from '@/components/TextExpandableComponent.vue';
let MuralAniversariantes = class MuralAniversariantes extends Vue {
    constructor() {
        super(...arguments);
        this.headers = [
            {
                text: 'Nome do aluno',
                value: 'aluno_nome',
                table: 'aluno',
                show: true,
                type: 'str',
            },
            {
                text: 'Idade do aluno hoje',
                sortable: true,
                value: 'idade',
                align: 'left',
                show: true,
            },
            {
                text: 'Dia do mês',
                sortable: true,
                value: 'dia',
                align: 'left',
                show: true,
            },
            {
                text: 'Celular do aluno',
                table: 'aluno',
                value: 'aluno_celular',
                show: true,
            },
            {
                text: 'Ocupação do aluno',
                table: 'aluno',
                value: 'aluno_ocupacao',
                show: true,
            },
            {
                text: 'O aluno é o seu responsável financeiro',
                table: 'aluno',
                value: 'aluno_responsavel_financeiro',
                type: 'bool',
                show: true,
            },
            {
                text: 'Nome do responsável',
                table: 'aluno',
                value: 'responsavel_nome',
                type: 'str',
                show: true,
            },
            {
                text: 'Celular do responsável',
                table: 'aluno',
                value: 'responsavel_celular',
                show: true,
            },
            {
                text: 'Contatos',
                table: 'aluno',
                sortable: false,
                value: 'aluno_contatos',
                align: 'left',
                show: true,
            },
            {
                text: 'Curso',
                table: 'curso',
                sortable: false,
                value: 'curso_nome',
                field: 'nome',
                align: 'left',
                show: true,
            },
            {
                table: 'contrato_status',
                text: 'Status',
                sortable: true,
                value: 'contrato_status_nome',
                field: 'nome',
                show: true,
            },
        ];
        this.selectedMonth = null;
    }
    get totals() {
        return analyticsStore.totals;
    }
    async buscar() {
        analyticsStore.setDefaultFilters({
            'EXTRACT(MONTH FROM aluno_data_nascimento)': {
                type: 'int',
                value: this.selectedMonth,
            },
        });
        await this.doSearchTotals();
        await analyticsStore.executeSearchItems();
    }
    async doSearchItems(queryIn) {
        return await alunoStore.getAniversariantesQuery(queryIn);
    }
    async doSearchTotals() {
        await analyticsStore.getAnalyticsAniversariantes({
            mes_id: this.selectedMonth,
        });
    }
    async mounted() {
        const d = new Date();
        const month = d.getMonth();
        this.selectedMonth = month + 1;
        analyticsStore.initAnalytics();
        analyticsStore.setFnSearchItems(alunoStore.getAniversariantesQuery);
        analyticsStore.setHeaders(this.headers);
        // tela que tem defaultFilter nao precisa ter o buscar() aqui
    }
};
MuralAniversariantes = __decorate([
    Component({
        components: {
            PeriodSelectorComponent,
            GenericListTotalCardComponent,
            GenericListItemsCardComponent,
            TextExpandableComponent,
        },
    })
], MuralAniversariantes);
export default MuralAniversariantes;
